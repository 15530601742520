const botonlist = [
  {
    texto: "Pitercios.es",
    img: "/imagenes/botones/www.png",
    link: "http://pitercios.es/",
    click: "home"
  },
  {
    texto: "Apuntes",
    img: "/imagenes/botones/apuntes.png",
    click: "apuntes"
  },
  /*
  {
    texto: "Fecha exams",
    img: "/imagenes/botones/calendario examenes.png",
    link: "https://calendar.google.com/calendar/u/0?cid=OWVkZDk3OTVjMjMxZmU2OTFmMTc3NDY3OWM5M2Y0MGZhNjdhOTJmZDY3MmVjNmM4YTM3ZTJiYjBjMDE5MDk5NUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t",
    click: "home"
  },
  */
  {
    texto: "Calculadoras",
    img: "/imagenes/botones/calculadora.png",
    click: "calculadoras"
  },
  {
    texto: "Juegos",
    img: "/imagenes/botones/juegos.png",
    click: "juegos"
  },
  {
    texto: "Discord",
    img: "/imagenes/botones/discord.png",
    link: "https://discord.gg/p29SxBSZGt",
    click: "home",
    id: "btnModal"
  },
  {
    texto: "Whatsapp",
    img: "/imagenes/botones/whatsapp.png",
    link: "https://wa.me/34609761038",
    click: "home"
  },
  {
    texto: "Inscripción",
    img: "/imagenes/botones/inscripcion.png",
    link: "http://pitercios.es/inscripcion/",
    click: "home"
  },
  {
    texto: "Reseña",
    img: "/imagenes/botones/resena.png",
    link: "https://bit.ly/34A18G9",
    click: "home"
  }, 
  
   
  /*
  {
    texto: "Navidades",
    img: "/imagenes/botones/horario.png",
    link: "https://pitercios.es/horario-navidades",
    click: "home"
  },
  */
   
  {
    texto: "Semana Santa",
    img: "/imagenes/botones/horario.png",
    link: "https://pitercios.es/horario-semana-santa",
    click: "home"
  },
  
  /*
  {
    texto: "Prueba",
    img: "/imagenes/botones/apuntes.png",
    click: "bach1"
  },
*/

  

];

export default botonlist;
